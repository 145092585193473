define('cramba/router', ['exports', 'cramba/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType
  });

  Router.map(function () {
    this.route('flashcards', { path: 'flashcards/:resource_id' }, function () {
      this.route('category', { path: ':category_name' });
    });
    this.route('form', { path: 'form/:resource_id' });
  });

  exports.default = Router;
});