define("cramba/controllers/application", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function getParameterByName(name, url) {
    if (!url) {
      url = window.location.href;
    }
    name = name.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i"),
        results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  exports.default = Ember.Controller.extend({
    queryParams: ['ResourceInstanceID', 'CurrentUserID', 'CourseID'],
    ResourceInstanceID: 0,
    CurrentUserID: null,
    CourseID: 0,
    i18n: Ember.inject.service(),
    getResourceInstanceID: function getResourceInstanceID() {
      // [cp] - cant get this to work:
      // this.get('ResourceInstanceID');

      // Store in session store for usercases that involve the url changing and the page being refreshed
      var resourceInstanceID = getParameterByName('ResourceInstanceID');
      if (resourceInstanceID) {
        sessionStorage.setItem('ResourceInstanceID', resourceInstanceID);
      } else {
        resourceInstanceID = sessionStorage.getItem('ResourceInstanceID');
      }
      console.debug("ResourceInstanceID: " + resourceInstanceID);
      return resourceInstanceID;
    },
    getCourseID: function getCourseID() {
      // Store in session store for usercases that involve the url changing and the page being refreshed
      var courseID = getParameterByName('CourseID');
      if (courseID) {
        sessionStorage.setItem('CourseID', courseID);
      } else {
        courseID = sessionStorage.getItem('CourseID');
      }
      console.debug("CourseID: " + courseID);
      return courseID;
    }
  });
});