define('cramba/services/account', ['exports', 'cramba/models/account', 'cramba/config/environment'], function (exports, _account, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var qsonicServer = _environment.default.APP.qsonic;
  var devMode = _environment.default.environment === "development";
  console.debug("QSonic: " + qsonicServer);

  var account = null;

  function _getUserAccount() {
    if (account != null) {
      return account;
    }

    var retrived = sessionStorage.getItem("Account");
    if (retrived) {
      console.debug("Restoring account from cache");

      return new Ember.RSVP.Promise(function (resolve) {
        resolve(_account.default.create(JSON.parse(retrived)));
      });
    }

    return Ember.$.getJSON(qsonicServer + "/v1/account/").fail(function (jqXHR) {
      if (jqXHR.status === 200) {
        alert("Please login to access this page");
      } else if (jqXHR.status === 404) {
        alert("404: Server Not Found");
      } else if (jqXHR.status === 500) {
        alert("500: Error on Server");
      } else {
        alert(jqXHR.status + ": Server returned error");
        console.error(jqXHR);
      }
      if (!devMode) {
        document.location.href = qsonicServer + "/login?ReturnURL=" + encodeURIComponent(document.location.href);
      }
    }).then(function (json) {
      sessionStorage.setItem("Account", JSON.stringify(json));
      return _account.default.create(json);
    });
  }

  exports.default = Ember.Service.extend({
    init: function init() {
      console.debug("User service initiating");
    },
    getUserAccount: function getUserAccount() {
      account = _getUserAccount();
      return account;
    }
  });
});