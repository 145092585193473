define("cramba/locales/fr/translations", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {
		"nav": {
			"dashboard": "Tableau de bord",
			"support": "Assistance",
			"tog_nav": "Toggle navigation"
		},
		"utils": {
			"my_account": "Mon compte",
			"sign_out": "Se déconnecter"
		},
		"confirm_text": "CONFIRMER",
		"form": {
			"save": "Enregistrer",
			"submit": "Soumettre",
			"save_submit": "Enregistrer & Soumettre",
			"return": "Retour",
			"confirm_submission": "Confirm Submission",
			"saved": "Enregistré",
			"saved_message": "Le formulaire a été enregistré avec succès",
			"are_you_sure": "Êtes-vous sûr(e)?",
			"warning": "Une fois le contenu publié vous ne pouvez plus faire de modification",
			"by": "par",
			"type_confirm": "Veuillez entrer le mot '{{confirm_text}}' pour soumettre vos réponses."
		},
		"tac": "Conditions générales"
	};
});