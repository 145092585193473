define('cramba/app', ['exports', 'cramba/resolver', 'ember-load-initializers', 'cramba/config/environment'], function (exports, _resolver, _emberLoadInitializers, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var qsonicServer = _environment.default.APP.qsonic;
  var devMode = _environment.default.environment === "development";

  Ember.$.ajaxPrefilter(function (options) {
    options.crossDomain = {
      crossDomain: true
    };
    options.xhrFields = {
      withCredentials: true
    };
  });

  if (devMode) {
    console.debug("***** DEVMODE *****");
    Ember.$.ajaxSetup({ cache: false });
  }

  var timeoutms = 0;

  function keepalive() {
    //console.debug("Ah, ha, ha, ha, stayin' alive, stayin' alive");
    Ember.$.getJSON(qsonicServer + "/session/keepalive").fail(function (jqXHR) {
      //console.log("Keep alive error:");
      console.error(jqXHR);
    }).then(function (data) {
      if (data && data.Timeout) {
        timeoutms = parseInt(data.Timeout);
        console.debug("QSonic timeout: " + timeoutms);
        return;
      }
      timeoutms = 0;
    });
  }

  // Set a 3 minute server ping
  setInterval(keepalive, 300000);

  var App = void 0;

  //DEPRECATION: Ember.MODEL_FACTORY_INJECTIONS is no longer required [deprecation id: ember-metal.model_factory_injections] See https://emberjs.com/deprecations/v2.x/#toc_id-ember-metal-model_factory_injections for more details.
  //Ember.MODEL_FACTORY_INJECTIONS = true;

  App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default,
    locale: 'fr'
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;
});