define('cramba/components/form-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.errors = [];
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      //console.log(this.pattern);

      if (this.isComplete && !this.get('readonly')) {
        this.set('readonly', 'readonly');
      }

      if (this.required && !this.get('required')) {
        this.set('required', 'required');
      }

      // if(this.pattern){
      //   this.set('pattern', this.pattern);
      // }

      // if(this.title){
      //   this.set('title', this.title);
      // }

      // if(this.placeholder){
      //   this.set('placeholder', this.placeholder);
      // }

      this.set('isTextarea', this.type === 'textarea');
      this.set('isText', this.type === 'text' || this.type === 'email' || this.type === 'date' || this.type === 'number' || this.type === 'tel');
      this.set('isHidden', this.type === 'hidden');
      this.set('isCheckbox', this.type === 'checkbox');
      this.set('isRadio', this.type === 'radio');
    }
  });
});