define('cramba/models/resource', ['exports', 'cramba/models/response'], function (exports, _response) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({

    //Flashcards (questions)
    questionCount: Ember.computed('data.questions', function () {
      return this.getQuestions().length;
    }),
    getQuestions: function getQuestions() {
      return this.get('data.questions');
    },
    setQuestions: function setQuestions(questions) {
      this.set("data.questions", questions);
    },


    //Form (fields)
    formFieldCount: Ember.computed('data.fields', function () {
      return this.getFields().length;
    }),
    getFormFields: function getFormFields() {
      return this.get('data.fields');
    },
    setFormFields: function setFormFields(fields) {
      this.set("data.fields", fields);
    },
    getFormResponces: function getFormResponces() {
      var responses = [];
      this.data.fields.forEach(function (field) {
        if (field.value) {
          responses.pushObject(_response.default.create({
            name: field.name,
            value: field.value
          }));
        }
      });
      return responses;
    },
    zap: function zap() {
      var self = this;
      var zapEndPoint = self.data.zap;
      if (zapEndPoint) {

        console.log(zapEndPoint);

        try {
          var xhr = new XMLHttpRequest();
          xhr.open("POST", zapEndPoint);
          xhr.send(JSON.stringify(self));
          console.log("Pushed to Zapier successfully!");
        } catch (e) {
          console.error(e);
        }

        //// Rather than posting directly to Zappire it would be safer to proxy through QSonic to avoid any CORS issues
        // var data = {
        //              proxyURL: zapEndPoint, 
        //              data: JSON.stringify(self)
        //            };

        // console.log(data);

        // Ember.$.ajax({
        //   type: "POST",
        //   url: qsonicServer +"/v1/catchhook/proxy",
        //   //contentType:"application/json; charset=utf-8",
        //   dataType:"json",
        //   data: data,
        //   success: function(ret) { console.log(ret); }
        // }).fail(function(error) {
        //   console.error(error);
        // });
      }
    }
  });
});