define('cramba/routes/flashcards', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        init: function init() {
            this.resourceInstanceID = this.controllerFor("application").getResourceInstanceID();
        },
        model: function model(params) {
            var resource = this.get('resourceService').getResourceByID(params.resource_id);
            var responses = this.get('responseService').getResponsesByInstanceID(this.resourceInstanceID);

            Ember.RSVP.Promise.resolve(responses).then(function (resp) {
                Ember.RSVP.Promise.resolve(resource).then(function (res) {
                    var questions = res.getQuestions();
                    for (var i in questions) {
                        var q = questions.objectAt(i);
                        if (typeof q === 'function') {
                            continue;
                        }
                        if (q.id) {
                            var correct = null;
                            var existingResponse = resp.getResponse(q.id)[0];
                            if (existingResponse) {
                                correct = existingResponse.value === "true";
                            }
                            Ember.set(q, "correct", correct);
                        }
                    }
                    resp.set('count', resp.content.length);
                });
            });

            return Ember.RSVP.hash({
                resourceID: params.resource_id,
                resourceInstanceID: this.resourceInstanceID,
                resource: resource
            });
        },

        resourceService: Ember.inject.service('resource'),
        responseService: Ember.inject.service('responses'),
        actions: {
            refresh: function refresh() {
                window.location.reload();
            },
            clearesponses: function clearesponses(resourceID, resourceInstanceID) {
                console.debug("Clearing existing responses");
                this.get('responseService').clearResponses(resourceInstanceID);
                window.location.href = "/flashcards/" + resourceID; // After flushing data force page refresh
            },
            didTransition: function didTransition() {
                Ember.$("#category-filter-all").addClass('active'); // Hack
            }
        },
        afterModel: function afterModel(model) {
            var title = model.resource.get('title');
            Ember.$(document).attr('title', title);
        },
        resourceInstanceID: 0
    });
});