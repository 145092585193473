define('cramba/routes/application', ['exports', 'cramba/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function calculateLocale(locales) {
    // whatever you do to pick a locale for the user:
    var language = navigator.languages[0] || navigator.language || navigator.userLanguage;
    var browserLang = locales.includes(language.toLowerCase()) ? language : 'en';
    console.debug("Found Lang: " + browserLang);
    return browserLang;
  }

  exports.default = Ember.Route.extend({
    model: function model() {
      var account = this.get('account').getUserAccount();
      // Ember.RSVP.Promise.resolve(account).then(function(data) {
      //     console.log(data);
      // });
      var server = _environment.default.APP.qsonic;
      var config = _environment.default.APP.config;

      var d = new Date();
      var y = d.getFullYear();

      return Ember.RSVP.hash({
        server: server,
        account: account,
        config: config,
        year: y
      });
    },

    account: Ember.inject.service('account'),
    i18n: Ember.inject.service(),
    afterModel: function afterModel() {
      var self = this;
      var i18n = self.get('i18n');
      var account = self.get('account').getUserAccount();

      Ember.RSVP.Promise.resolve(account).then(function (data) {
        var locale = "en";
        if (data.locale) {
          locale = data.locale;
        } else {
          locale = calculateLocale(i18n.get('locales'));
        }
        self.set('i18n.locale', locale);
      });
    }
  });
});