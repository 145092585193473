define("cramba/locales/en/translations", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {
		"nav": {
			"dashboard": "Dashboard",
			"support": "Support",
			"tog_nav": "Toggle navigation"
		},
		"utils": {
			"my_account": "My account",
			"sign_out": "Sign out"
		},
		"confirm_text": "CONFIRM",
		"form": {
			"save": "Save",
			"submit": "Submit",
			"save_submit": "Save & Submit",
			"return": "Return",
			"confirm_submission": "Confirm Submission",
			"saved": "Saved",
			"saved_message": "This form was successfully saved",
			"are_you_sure": "Are you sure?",
			"warning": "Once submited you can no longer edit",
			"by": "by",
			"type_confirm": "Please type the word {{confirm_text}} to submit."
		},
		"tac": "Terms and Conditions"
	};
});