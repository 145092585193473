define('cramba/components/flash-card', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'section',
        classNameBindings: ['correct:hidden'],
        correct: false,
        incorrect: false,
        // init(){
        //     this._super();
        // },
        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            var correct = this.get('correct');
            if (correct != null) {
                if (correct) {
                    this.set("correct", true);
                } else {
                    this.set("incorrect", false);
                    var $card = this.$(".card");
                    if ($card) {
                        $card.addClass("border-danger");
                    }
                }
            }
        },

        actions: {
            show: function show() {
                this.$(".marked").show();
                this.$(".unmarked").hide();
            },
            correct: function correct(resourceInstanceID, questionID) {
                //console.log(resourceInstanceID);
                this.$(".card").removeClass("border-danger").addClass("border-success").fadeTo(300, 0.2, function () {
                    Ember.$(this).slideUp("slow");
                });

                Ember.set(this, "incorrect", false);
                this.get('responseService').saveResponse(resourceInstanceID, questionID, true);
            },
            incorrect: function incorrect(resourceInstanceID, questionID) {
                //console.log(resourceInstanceID);
                this.$(".card").slideUp("slow");
                Ember.set(this, "incorrect", true);
                this.get('responseService').saveResponse(resourceInstanceID, questionID, false);
            }
        },
        responseService: Ember.inject.service('responses')
    });
});