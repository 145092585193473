define("cramba/routes/flashcards/category", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        init: function init() {
            this.resourceInstanceID = this.controllerFor("application").getResourceInstanceID();
        },
        model: function model(params) {
            var resourceModel = this.modelFor("flashcards");
            var resource = this.get('resourceService').filterResourceQuestionsByCategory(resourceModel.resourceID, params.category_name);
            var responses = this.get('responseService').getResponsesByInstanceID(this.resourceInstanceID);

            Ember.RSVP.Promise.resolve(responses).then(function (resp) {
                Ember.RSVP.Promise.resolve(resource).then(function (res) {
                    var questions = res.getQuestions();
                    for (var i in questions) {
                        var q = questions.objectAt(i);
                        if (q.id) {
                            var correct = null;
                            var existingResponse = resp.getResponse(q.id)[0];
                            if (existingResponse) {
                                correct = existingResponse.value === "true";
                            }
                            Ember.set(q, "correct", correct);
                        }
                    }
                    resp.set('count', resp.content.length);
                });
            });

            return Ember.RSVP.hash({
                resourceID: resourceModel.resourceID,
                resourceInstanceID: this.resourceInstanceID,
                resource: resource
            });
        },

        resourceService: Ember.inject.service('resource'),
        responseService: Ember.inject.service('responses'),
        renderTemplate: function renderTemplate(controller, model) {
            this.render('flashcards', {
                model: model
            });
        },

        actions: {
            didTransition: function didTransition() {
                setTimeout(function () {
                    Ember.$("#category-filter-all").remove(); //.removeClass('active'); // Hack
                }, 75);
            }
        }
    });
});