define('cramba/routes/form', ['exports', 'cramba/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var qsonicServer = _environment.default.APP.qsonic;
    var devMode = _environment.default.environment === "development";

    exports.default = Ember.Route.extend({
        i18n: Ember.inject.service(),
        resourceInstanceID: 0,
        courseID: 0,
        resourceService: Ember.inject.service('resource'),
        responseService: Ember.inject.service('responses'),

        init: function init() {
            this.resourceInstanceID = this.controllerFor("application").getResourceInstanceID();
            this.courseID = this.controllerFor("application").getCourseID();
        },
        model: function model(params) {
            var resourcePromise = this.get('resourceService').getResourceByID(params.resource_id);
            var responsesPromise = this.get('responseService').getResponsesByInstanceID(this.resourceInstanceID);

            Ember.RSVP.Promise.all([responsesPromise, resourcePromise]).then(function (values) {
                var responses = values[0];
                var resource = values[1];

                var fields = resource.getFormFields();
                for (var i in fields) {
                    var field = fields.objectAt(i);
                    if (typeof field === 'function') {
                        continue;
                    }
                    if (field.name) {
                        var existingResponse = responses.getResponse(field.name)[0];
                        if (existingResponse && existingResponse.value) {
                            Ember.set(field, "value", existingResponse.value);
                        }
                    }
                }
                responses.set('count', responses.content.length);
                Ember.set(resource, "isComplete", responses.isComplete);

                //console.debug(responses);  
                if (devMode) {
                    console.debug(resource);
                }
            });

            return Ember.RSVP.hash({
                resourceID: params.resource_id,
                resourceInstanceID: this.resourceInstanceID,
                courseID: this.courseID,
                resource: resourcePromise,
                actionPerformed: "Saved",
                confirmtext: ""
            });
        },


        actions: {
            save: function save(resourceInstanceID) {
                var responses = this.currentModel.resource.getFormResponces();
                this.get('responseService').saveResponses(resourceInstanceID, responses, function () {
                    Ember.$('#saved').modal('show');
                });
            },
            submit: function submit(resourceInstanceID) {
                var responses = this.currentModel.resource.getFormResponces();
                this.get('responseService').saveResponses(resourceInstanceID, responses, function () {
                    Ember.$('#confirm').modal('show');
                    setTimeout(function () {
                        Ember.$("#confirmtext").focus();
                    }, 500);
                });

                return true;
            },
            confirm: function confirm(resourceInstanceID) {
                var self = this;

                if (!resourceInstanceID) {
                    alert("Error saving form");
                }
                var i18n = this.get('i18n');
                var confirm_text = i18n.t("confirm_text");
                var form_type_confirm = i18n.t("form.type_confirm", { confirm_text: confirm_text });

                if (!confirm_text) {
                    confirm_text = "confirm";
                    console.error("Missing 'confirm_text'.  Setting default");
                }
                confirm_text = confirm_text.toString().toLowerCase();
                console.debug("confirm_text: " + confirm_text);

                var usersConfirmText = self.currentModel.confirmtext;
                if (usersConfirmText && usersConfirmText.toString().trim().toLowerCase().indexOf(confirm_text) !== -1) {

                    Ember.$('#confirm').modal('toggle');
                    self.get('resourceService').setComplete(resourceInstanceID, function () {
                        self.currentModel.resource.zap();
                        Ember.set(self, 'currentModel.actionPerformed', "Submited");
                        Ember.$('#saved').modal('show');
                    });
                } else {
                    alert(form_type_confirm);
                    Ember.$("#confirmtext").focus();
                }
                return true;
            },
            return: function _return(courseID) {
                // Get courseID here
                if (courseID) {
                    window.location.href = qsonicServer + "/account/courses/" + courseID + "/outline";
                } else {
                    window.location.href = qsonicServer + "/dashboard";
                }
            },
            clearesponses: function clearesponses(resourceID, resourceInstanceID) {
                console.debug("Clearing existing responses");
                this.get('responseService').clearResponses(resourceInstanceID);
                window.location.href = "/form/" + resourceID; // After flushing data force page refresh
            }
        }
    });
});