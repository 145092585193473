define('cramba/services/resource', ['exports', 'cramba/models/resource', 'cramba/config/environment'], function (exports, _resource, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var qsonicServer = _environment.default.APP.qsonic;
  var resource = null;

  function _getResourceByID(resourceID) {
    if (resource != null) {
      return resource;
    }

    var retrived = sessionStorage.getItem("Resource_" + resourceID);
    if (retrived) {
      console.debug("Restoring resource from cache");
      return new Ember.RSVP.Promise(function (resolve) {
        resolve(_resource.default.create(JSON.parse(retrived)));
      });
    }

    return Ember.$.getJSON(qsonicServer + "/v1/resources/" + resourceID).fail(function (jqXHR, textStatus) {
      console.error(textStatus);
      if (jqXHR.status === 200 || jqXHR.status === 401) {
        // QSonic doesn't return correct error code for not authenticated
        alert("Please login to access this page");
        document.location.href = qsonicServer + "/login?ReturnURL=" + encodeURIComponent(document.location.href);
      } else if (jqXHR.status === 403) {
        alert("You do not have access to this resource");
        document.location.href = qsonicServer + "/dashboard";
      } else if (jqXHR.status === 404) {
        alert("404: Server Not Found");
      } else if (jqXHR.status === 500) {
        alert("500: Error on Server");
      } else {
        alert("Server returned: " + textStatus + " (" + jqXHR.status + ")\n\n" + jqXHR.statusText);
        console.log(jqXHR);
      }
    }).then(function (json) {
      json.data = JSON.parse(json.data); //hack
      sessionStorage.setItem("Resource_" + resourceID, JSON.stringify(json));
      console.debug("Resourse data from QSonic");
      resource = _resource.default.create(json);
      console.debug(resource); //JSON.stringify(json)
      return resource;
    });
  }

  exports.default = Ember.Service.extend({
    accountService: Ember.inject.service('account'),
    init: function init() {
      console.debug("Resource service initiating");
    },
    setComplete: function setComplete(resourceInstanceID, callback) {
      console.info("Marking resource as complete");
      var accountPromise = this.get('accountService').getUserAccount();

      Ember.RSVP.Promise.resolve(accountPromise).then(function (account) {
        Ember.$.ajax({
          type: "POST",
          url: qsonicServer + "/v1/profiles/" + account.partyRoleID + "/resourceinstances",
          data: {
            "PartyRoleID": account.partyRoleID,
            "ResourceInstanceID": resourceInstanceID,
            "ResourceInstanceStatusID": 3
          },
          success: function success(data) {
            //console.debug(data);
            if (typeof callback === "function") {
              callback.call(data);
            }
          },
          failure: function failure(errMsg) {
            console.error(errMsg);
            alert(errMsg);
          }
        });
      });
    },
    getResourceByID: function getResourceByID(resourceID) {
      console.debug("ResourceID: " + resourceID);
      resource = _getResourceByID(resourceID);
      return resource;
    },
    filterResourceQuestionsByCategory: function filterResourceQuestionsByCategory(resourceID, categoryName) {
      console.debug("ResourceID: " + resourceID);
      console.debug("Category: " + categoryName);
      resource = _getResourceByID(resourceID);
      return Ember.RSVP.Promise.resolve(resource).then(function (r) {
        var clone = _resource.default.create(JSON.parse(JSON.stringify(r)));
        //var clone = Ember.copy(r, true);
        var questions = r.getQuestions();
        var filteredQuestions = Ember.$.grep(questions, function (q) {
          if (q && q.c) {
            var categories = q.c.map(function (cat) {
              return cat.toLowerCase();
            });
            //return categoryName.toLowerCase() === categories[0];
            return Ember.$.inArray(categoryName.toLowerCase(), categories) !== -1;
          } else {
            console.error("Question: " + q.id + " (missing categories)");
          }
        });
        clone.setQuestions(filteredQuestions);
        return clone;
      });
    }
  });
});