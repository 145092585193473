define('cramba/services/responses', ['exports', 'cramba/models/response', 'cramba/config/environment'], function (exports, _response, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var qsonicServer = _environment.default.APP.qsonic;

  var responses = [];

  function restoreResponsesFromLocal(resourceInstanceID) {
    console.log("Cached response");
    var localResourceInstanceResponses = localStorage.getItem("ResourceInstanceResponses_" + resourceInstanceID);
    if (localResourceInstanceResponses) {
      console.debug("Restoring previous responses");
      Ember.$.each(JSON.parse(localResourceInstanceResponses), function (index, resp) {
        var itemResponse = _response.default.create({
          name: resp.name,
          value: resp.value
        });
        responses.pushObject(itemResponse);
      });
    }

    var result = Ember.ArrayProxy.create({
      content: Ember.A(responses),
      getResponse: function getResponse(name) {
        return Ember.$.grep(responses, function (resp) {
          if (resp && resp.name) {
            return name.toLowerCase() === resp.name.toLowerCase();
          }
        });
      }
    });

    return new Ember.RSVP.Promise(function (resolve) {
      resolve(result);
    });
  }

  function getResponses(resourceInstanceID) {
    if (!resourceInstanceID) {
      alert("Failed to restore previous responses \nNo ResourceInstanceID provided \nRedirect back to course list - please try again.");
      document.location.href = qsonicServer + "/dashboard";
    }

    //return restoreResponsesFromLocal(resourceInstanceID);  // used to test local restore

    console.debug("Requesting responses from server");
    return Ember.$.getJSON(qsonicServer + "/v1/assessments/storage/" + resourceInstanceID).fail(function (jqXHR, textStatus) {
      console.error("Failed getting response from server: " + textStatus);
      restoreResponsesFromLocal(resourceInstanceID);
    }).then(function (json) {
      console.debug(json);
      for (var key in json.data) {
        if (json.data.hasOwnProperty(key)) {
          //console.debug(key + " -> " + json.data[key]);
          responses.pushObject(_response.default.create({
            name: key,
            value: json.data[key]
          }));
        }
      }
      return Ember.ArrayProxy.create({
        isComplete: json.isComplete,
        content: Ember.A(responses),
        getResponse: function getResponse(name) {
          return Ember.$.grep(responses, function (resp) {
            if (resp && resp.name) {
              return name.toLowerCase() === resp.name.toLowerCase();
            }
          });
        }
      });
    });
  }

  function serializeResponses() {
    var result = "";
    responses.forEach(function (response) {
      if (response.value) {
        result += response.name + "=" + response.value + "&";
      }
    });
    return result.substring(0, result.length - 1); // Remove trailing &
  }

  exports.default = Ember.Service.extend({
    getResponsesByInstanceID: function getResponsesByInstanceID(resourceInstanceID) {
      return getResponses(resourceInstanceID);
    },
    saveResponse: function saveResponse(resourceInstanceID, name, value) {
      if (!resourceInstanceID) {
        console.error("Failed to save responses.  [Invalid resourceInstanceID]");
        return;
      }
      console.info("Saving response");

      var existing = responses.findBy("name", name);
      if (existing) {
        //console.log("Updating: "+ name);
        existing.set("value", value);
      } else {
        var response = _response.default.create({
          name: name,
          value: value
        });
        responses.pushObject(response);
      }
      localStorage.setItem("ResourceInstanceResponses_" + resourceInstanceID, JSON.stringify(responses));
      //console.debug(JSON.stringify(responses));

      Ember.$.ajax({
        type: "POST",
        url: qsonicServer + "/v1/assessments/storage/" + resourceInstanceID + "!PUT",
        data: {
          "name": name,
          "value": value
        },
        success: function success() {
          console.log("Response saved");
          //console.debug(data);
        },
        error: function error(xhr, ajaxOptions, thrownError) {
          console.log(xhr.status);
          console.error(thrownError);
        }
      });
    },
    saveResponses: function saveResponses(resourceInstanceID, newResponses, callback) {
      if (!resourceInstanceID) {
        console.error("Failed to save responses.  [Invalid resourceInstanceID]");
        return;
      }
      console.info("Saving responses");

      responses.clear();
      responses.pushObjects(newResponses);
      localStorage.setItem("ResourceInstanceResponses_" + resourceInstanceID, JSON.stringify(responses));

      Ember.$.ajax({
        type: "POST",
        url: qsonicServer + "/v1/assessments/storage/" + resourceInstanceID,
        data: {
          "data": serializeResponses()
        },
        success: function success(data) {
          console.log("Save successfull");
          //console.debug(data);
          if (typeof callback === "function") {
            callback.call(data);
          }
        },
        error: function error(xhr, ajaxOptions, thrownError) {
          console.log(xhr.status);
          console.error(thrownError);
        }
      });
    },
    clearResponses: function clearResponses(resourceInstanceID) {
      responses.clear();
      localStorage.removeItem("ResourceInstanceResponses_" + resourceInstanceID);
      sessionStorage.removeItem("Resource_" + resourceInstanceID);
      if (resourceInstanceID) {
        Ember.$.ajax({
          type: "POST",
          url: qsonicServer + "/v1/assessments/storage/" + resourceInstanceID + "!DELETE",
          success: function success() {
            console.debug("Cleaned responses from server");
          }
        });
      }
    }
  });
});