define('cramba/routes/index', ['exports', 'cramba/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            //console.log(ENV);
            var devMode = _environment.default.environment === "development";
            var server = _environment.default.APP.qsonic;

            if (!devMode) {
                document.location.href = server + "/dashboard";
            }

            return {
                server: server,
                environment: _environment.default.environment,
                dev: devMode
            };
        }
    });
});